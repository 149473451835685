// @ts-nocheck
import Cookies from 'js-cookie';

function gtag() {
  // eslint-disable-next-line prefer-rest-params
  window.dataLayer.push(arguments);
}

export const markConsentAndDate = (date: string) => {
  Cookies.set('cookies_consent', true.toString(), { expires: 365 });
  Cookies.set('cookies_consent_given', date, {
    expires: 365,
  });
};

export type Storages =
  | 'analytics_storage'
  | 'ad_storage'
  | 'personalisation_storage'
  | 'ad_user_data'
  | 'ad_personalization';

/* eslint-disable camelcase */
export const setDefaultConsents = () => {
  gtag('consent', 'default', {
    personalisation_storage: 'denied',
    ad_storage: 'denied',
    analytics_storage: 'denied',
    ad_user_data: 'denied',
    ad_personalization: 'denied',
  });
};
/* eslint-enable camelcase */

export const agreeToAll = () => {
  const interval = window.setInterval(() => {
    Cookies.set(`cookies_consent_ad_storage`, true.toString(), {
      expires: 365,
    });
    Cookies.set(`cookies_consent_analytics_storage`, true.toString(), {
      expires: 365,
    });

    /* eslint-disable camelcase */
    gtag('consent', 'update', {
      personalisation_storage: 'granted',
      ad_storage: 'granted',
      analytics_storage: 'granted',
      ad_user_data: 'granted',
      ad_personalization: 'granted',
    });
    /* eslint-enable camelcase */

    window.clearInterval(interval);
  }, 1_000);
};

type UpdateProps = { storage: Storages; consent: boolean };
export const updateSingleConsent = ({ storage, consent }: UpdateProps) => {
  gtag('consent', 'update', {
    [storage]: consent ? 'granted' : 'denied',
  });
};
